<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
    <v-card flat class="card-datatable">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="11" md="4" xl="3" lg="3">
            <v-text-field
              outlined
              dense
              v-model="schoolDataTable[$API_KEYS.SEARCH]"
              label="Search by School Name"
              append-icon="mdi-magnify"
              type="text"
              color="#38227A"
              class="search-input"
              height="43px"
            >
            </v-text-field>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.xsOnly"
            class=""
            align-self="center"
            cols="1"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
                  >mdi-menu</v-icon
                >
              </template>
              <v-list>
                <v-list-item-group active-class="active-class">
                  <v-list-item
                    @click="
                      toggleAddEditViewSchoolModal({
                        show: true,
                        type: 'add',
                      })
                    "
                  >
                    <v-list-item-title> Add School </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="route">
                    <v-list-item-title> Bulk Import </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <v-col
            class="text-right"
            v-if="
              $vuetify.breakpoint.smAndUp &&
              this.layout === 'AdminDashboardLayout'
            "
            cols="4"
            md="5"
            xl="3"
          >
            <v-btn
              text
              style="border: 1px solid #38227a"
              class="text-capitalize mr-5 px-7"
              @click="route"
              v-if="loading"
            >
              <span>bulk import</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              @click="
                toggleAddEditViewSchoolModal({
                  show: true,
                  type: 'add',
                })
              "
              class="text-capitalize px-7"
            >
              <span>Add School</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="table-div">
      <v-data-table
        fixed-header
        :options.sync="dataOptions"
        hide-default-footer
        :headers="schoolDataTable.headers"
        :items="schoolDataTable.items"
        :loading="schoolDataTable.loading"
        loading-text="Loading... Please wait"
        class="elevation-1"
        mobile-breakpoint="0"
        :items-per-page="
          schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
      >
        <template v-slot:item="row">
          <tr height="80px" class="text-flex">
            <td class="text-center row-item px-0">
              <v-row class="justify-center">
                <v-col class="py-0" cols="10" md="12" lg="11" xl="8">
                  <div class="d-flex align-center" style="">
                    <img width="45" :src="row.item.logo_url" />
                    <div class="ml-2">
                      <div>
                        <div
                          class="text-left font-weight-bold"
                          style="font-size: 14px"
                        >
                          {{ row.item.school_name }}
                        </div>
                      </div>
                      <div>
                        <div
                          class="text-left"
                          style="font-size: 12px; color: grey"
                        >
                          {{ row.item.district_name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </td>

            <td class="text-center row-item py-2">
              <v-row justify="center">
                <v-col cols="10">
                  <div class="text-center">
                    <div style="font-weight: 600">{{ row.item.user_name }}</div>
                    <div style="font-size: 13px; color: grey">
                      {{ row.item.user_email }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </td>

            <td class="text-center row-item px-0">
              {{ row.item.total_fund_raised_2 }}
            </td>
            <td class="text-center row-item">
              {{ row.item.total_students_registered }}
            </td>
            <td class="text-center row-item">
              <div style="font-weight: 700">
                {{ row.item.wizfit_video
                }}<span style="color: grey; font-size: 14px">
                  /{{ row.item.total_wizfit_video }}</span
                >
              </div>
              <div style="color: #067605">
                {{ row.item.percentage_wizfit_video }}%
              </div>
            </td>
            <td class="text-center row-item">{{ row.item.total_shares }}</td>
            <td class="text-center row-item">
              <tr>
                <td>
                  <v-chip @click="viewPublicDonationPageHandler(row.item.campaign_detail)" color="#F2F2F2" class="actionColumn-btn">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="25"
                        >
                          mdi-open-in-new
                        </v-icon>
                      </template>
                      <span>Public Page</span>
                    </v-tooltip>
                  </v-chip>
                  <v-chip
                    class="actionColumn-btn"
                    color="#F2F2F2"
                    @click="viewSchoolCampaign(row.item)"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="22"
                        >
                          mdi-eye
                        </v-icon>
                      </template>
                      <span>Dashboard</span>
                    </v-tooltip>
                  </v-chip>
                </td>
              </tr>
            </td>
          </tr>
        </template>
        <template
          v-slot:footer
          v-if="schoolDataTable.items[0] && $vuetify.breakpoint.smAndUp"
        >
          <div>
            <v-divider></v-divider>
            <v-row no-gutters class="pt-6 px-2">
              <v-col
                class="mb-4"
                align-self="center"
                cols="12"
                xs="12"
                sm="6"
                md="4"
              >
                <div
                  class="
                    justify-center justify-md-start
                    d-flex
                    px-5
                    tableHeader-text
                  "
                >
                  <div class="alignSelf-center mr-3">Show</div>
                  <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                    <v-select
                      class=""
                      :items="itemsPerPageList"
                      hide-details
                      v-model="
                        schoolDataTable[$API_KEYS.PAGE_INFO][
                          $API_KEYS.ITEMS_PER_PAGE
                        ]
                      "
                      dense
                    ></v-select>
                  </div>

                  <div class="alignSelf-center">
                    {{
                      schoolDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    }}
                    of
                    {{
                      schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                    }}
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="4" md="4">
                <div>
                  <v-pagination
                    v-model="
                      schoolDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    "
                    :length="
                      schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                    "
                    :total-visible="5"
                    color="#D30024"
                    class="pagination"
                  >
                  </v-pagination>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-card
      v-if="schoolDataTable.items[0] && $vuetify.breakpoint.xsOnly"
      class="footer-card"
    >
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="
                justify-center justify-md-start
                d-flex
                px-5
                tableHeader-text
              "
            >
              <div class="alignSelf-center mr-3">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPageList"
                  hide-details
                  v-model="
                    schoolDataTable[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  dense
                ></v-select>
              </div>

              <div class="alignSelf-center">
                {{
                  schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                }}
                of
                {{ schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE] }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="
                  schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                "
                :length="
                  schoolDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                "
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <add-edit-view-school-modal
      :SchoolFormData="SchoolFormData"
      @reload="getSchoolManagementData()"
    ></add-edit-view-school-modal>
  </div>
</template>
<script>
import { ITEMS, LOADING, SCHOOL } from "@/constants/ModuleKeys";
import {
  DATA,
  PAGE_INFO,
  ITEMS_PER_PAGE,
  SCHOOL_LIST,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
  CAMPAIGN_ID,
} from "@/constants/APIKeys";
import Axios from "@/api/BaseAxios";
import { API_ADMIN_SCHOOL_MANAGEMENT_LIST } from "@/constants/APIUrls";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import authToken from "@/common/authToken";
import { ROUTER_URL } from "@/constants/urls";
import Helper from "@/helper";
export default {
  name: "SchoolManagement",
  components: {
    AddEditViewSchoolModal: () =>
      import("../AddEditViewSchoolModal/AddEditViewSchoolModal.vue"),
  },
  data() {
    return {
      dataOptions: { sortBy: [], sortDesc: [] },
      schoolDataTable: {
        headers: [
          {
            text: "School Info",
            align: "center",
            value: "school_name",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            text: "Contact Person Info",
            align: "center",
            value: "user_name",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            text: "Fund Raised",
            align: "center",
            value: "total_fund_raised",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            text: "# Registered Students",
            align: "center",
            value: "total_students_registered",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            text: "Wizfit Video",
            value: "wizfit_video",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            text: "# Shares",
            value: "total_shares",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "12%",
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: true,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
      },
      SchoolFormData: {
        flag: false,
        loading: false,
        disabled: false,
        type: "add",
        item: [],
      },
      itemsPerPageList: [10, 15, 20, 30, 50, 100],
    };
  },
  
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    userType() {
      return authToken.decodedToken().user_type;
    },
    userTeam() {
      return authToken.decodedToken().user_team;
    },
    layout() {
      return this.$route.meta.layout;
    },
  },
  watch: {
    //Watcher for detecting change in PAGE_NUMBER
    "schoolDataTable.page_info.page_number": function () {
      this.getSchoolManagementData();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "schoolDataTable.page_info.detail_count": function () {
      this.getSchoolManagementData();
    },
    //Watcher for detecting change in SEARCH
    "schoolDataTable.search": function () {
      this.debounceGetSchoolManagementData();
    },
    showModal: function (value) {
      console.log(value);
    },
    "dataOptions.sortBy": function () {
      if (this.dataOptions.sortBy.length !== 0) {
        this.schoolDataTable[SORTING_KEY] = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getSchoolManagementData();
      } else {
        this.schoolDataTable[SORTING_KEY] = "";
        this.getSchoolManagementData();
      }
    },
  },
  methods: {
    ...mapActions({
      // district Modal
      toggleAddEditViewSchoolModal: "schoolManagement/toggleModal",
    }),
    route() {
      this.$router.push("/upload");
    },
    getSchoolManagementData() {
      const self = this;
      this.schoolDataTable.loading = true;
      const successHandler = (res) => {
        console.log(
          "ITEMS_PER_PAGE: ",
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE],
          "PAGE_NUMBER: ",
          res[DATA][PAGE_INFO][PAGE_NUMBER],
          "TOTAL_PAGE: ",
          res[DATA][PAGE_INFO][TOTAL_PAGE],
          "SCHOOL_LIST: ",
          res[DATA][SCHOOL_LIST]
        );
        self.schoolDataTable.items = res[DATA][SCHOOL_LIST];
        self.schoolDataTable[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.schoolDataTable[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.schoolDataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];
        self.schoolDataTable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.schoolDataTable.loading = false;
      };
      let dataTableParams = {};
      dataTableParams[SEARCH] = this.schoolDataTable[SEARCH];
      dataTableParams[ITEMS_PER_PAGE] =
        this.schoolDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      dataTableParams[PAGE_NUMBER] =
        this.schoolDataTable[PAGE_INFO][PAGE_NUMBER];
      dataTableParams[SORTING_KEY] = "";
      if (this.layout === "CampaignDashboardLayout") {
        dataTableParams["campaign_id"] =
          this.selectedCampaignData.campaignData.id;
        if (this.userTeam === "admin") {
          console.log("in the block dhdhfdhfdhf")
          console.log(this.selectedCampaignData.organizationData.id)
          dataTableParams["district_id"] =
            this.selectedCampaignData.organizationData.id;
        }
      }
      console.log(dataTableParams, "formdata");
      Axios.request_GET(
        API_ADMIN_SCHOOL_MANAGEMENT_LIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    viewSchoolCampaign(school) {
      const { id, logo_url, school_name } = school;
      console.log({ school });
      let campaignData = this.selectedCampaignData.campaignData;
      let organizationData = { logo_url: logo_url, name: school_name, id: id };
      campaignData = { ...campaignData, school };
      let selectedCampaign = { school: { campaignData, organizationData } };
      if (localStorage.getItem("selectedCampaign") !== null) {
        let selectedCampaignPersist = JSON.parse(
          localStorage.getItem("selectedCampaign")
        );
        selectedCampaign = { ...selectedCampaignPersist, ...selectedCampaign };
      }
      // localStorage.setItem("selectedCampaign", JSON.stringify({...selectedCampaign}));
      console.log(selectedCampaign);
      let routeData = this.$router.resolve({
        name: ROUTER_URL.schoolPanel.children.dashboard.name,
        query: {
          [SCHOOL]: school.id,
          [CAMPAIGN_ID]: school.campaign_id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    viewPublicDonationPageHandler (item) {
      const {sub_domain } = item;
    //  const queryParam = 'school=' + id;
      Helper.openPublicDonationPage(sub_domain)
    }
  },
  mounted() {
    this.getSchoolManagementData();
    console.log(this.selectedCampaignData,"campaign detail")
  },
  created() {
    this.$root.$refs.districtSchool = this;
    this.debounceGetSchoolManagementData = _.debounce(
      this.getSchoolManagementData,
      500
    );
  },
};
</script>
<style scoped>
.active-class {
  border-right: 4px solid #38227a;
  border-left: 4px solid #38227a;
  border-radius: 4px;
}
.card-datatable.v-card {
  max-height: 80px;
  margin-bottom: -2px;
  display: flex;
}
.text-capitalize.v-btn {
  font-family: Lato;
}
.v-btn >>> {
  letter-spacing: 0px;
  font-weight: 600;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer-card.v-card {
  max-height: 70px;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.trow {
  height: 50px;
  background-color: #eeeeee;
}
.theader {
  font-weight: 400;
  font-size: 13px;
  padding: 12px;
  text-align: center;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
  border-radius: 0px;
}
.v-list.menu-list {
  border: 1px solid #d2d2d2;
  box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.v-menu__content.theme--light.menuable__content__active {
  border-radius: 6px;
}
.alignSelf-center {
  align-self: center;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 45px;
  }
  .v-input.search-input {
    width: 240px;
  }
  .v-icon.notranslate.mobile-menu {
    position: relative;
    bottom: 13px;
  }
  .v-data-table-header {
    display: flex;
    width: 100%;
    overflow-x: scroll;
  }
  .table-div {
    overflow-x: scroll;
  }
  .v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
    min-width: 1100px;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
